import { useState } from 'react';
import axios from 'axios';
import './App.css';
function App() {
  const [page,pagevalue] = useState("home");
  const [token,tokenvalue] = useState(localStorage.getItem("token")||"")
  const [user,userid] = useState(localStorage.getItem("user")||"")
  const [paragraph,paragraphvalue] = useState("");
  return (
    <>
    <div className="App">
      <header className="App-header">
      <div className="index_Logo__3AJ2q"><svg viewBox="0 0 20 20" width="23" height="23" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M5.70480219,0 L10.4096044,3.41834667 L8.61252986,8.94934776 L2.79707453,8.94934776 L1,3.41834667 L5.70480219,0 Z M5.70480219,20 L1,16.5816533 L2.79707453,11.0506522 L8.61252986,11.0506522 L10.4096044,16.5816533 L5.70480219,20 Z M18.8709653,12.9678909 L13.3400514,14.7649021 L9.92167142,10.0599974 L13.3399103,5.35519519 L18.8708781,7.15237223 L18.8709653,12.9678909 Z" fill="#FFFFFF"></path></g></svg></div>
    {page=="home"?<>
        <p>
          15 Post in one Click on Medium.com
        </p>
        <button onClick={()=>{pagevalue("token")}}>Get Start</button></>:<></>}
    {page=="token"?<>
        <p>
        <input value={token} placeholder='Paste your Token' onChange={(e)=>{tokenvalue(e.target.value)}}/>
        </p>
        <button onClick={()=>{
          if(token !== ""){
            pagevalue("userid")
            localStorage.setItem("token",token)
          }else{
            alert("Please paste your token!")
          }
        }}>Next</button></>:<></>}
        {page=="userid"?<>
        <p>
        <input value={user} placeholder='Paste your UserID' onChange={(e)=>{userid(e.target.value)}}/>
        </p>
        <button onClick={()=>{
          if(user !== ""){
            pagevalue("paragraph")
            localStorage.setItem("user",user)
          }else{
            alert("Please paste your UserID!")
          }
        }}>Next</button></>:<></>}
    {page=="paragraph"?<>
        <p>
        <input value={paragraph} placeholder='Paste your Paragraph' onChange={(e)=>{paragraphvalue(e.target.value)}}/>
        </p>
        <button onClick={()=>{
          if(paragraph!==""){
            const post_data = {
              title: "testing pusrpose",
              contentFormat: "html",
              content: `<h1>testing pusrpose</h1>`,
              tags: [`shfdh11111`],
              publishStatus: "public",
            };
            const headers = {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            };
            axios.post("https://api.medium.com/v1/users/1df79544f5b15b691a37bb0e2d05fc759a97f57e2379d0dee745a69e7ac03b54b/posts/", post_data, { headers })
            .then(response => {
              console.log(response.data);
              pagevalue("completed");
            })
            .catch(error => {
              console.error(error);
            });
          }else{
            alert("Please paste data of news!")
          }
        }}>Next</button></>:<></>}
    {page=="completed"?<><p>
          Completed 
        </p>
        <button onClick={()=>{pagevalue("home")}}>Finshed</button></>
        :<></>}
    </header>
    </div>
    </>
  );
}

export default App;
